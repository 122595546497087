<template>
  <BaseDialog
    title="提示"
    v-bind="$attrs"
    width="576px"
    @close="$emit('close')"
    @confirm="$emit('confirm')"
  >
    <div class="mb-[40px]">
      <p>
        將變更所有舊會員的「權益政策同意狀態」，由「已同意」改為「未同意」。
        此變更不可回復，是否確認變更？
      </p>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
export default defineComponent({
  name: 'ConfirmModifyMembersPolicyStatusModal',
  components: { BaseDialog },
  setup (props, { emit }) {
    const loading = ref(false)
    return { loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
