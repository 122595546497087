<template>
  <div class="user-info-setting-form">
    <template v-if="displayFormData.length">
      <el-card
        v-for="(item, index) in displayFormData"
        :key="item.id"
        class="box-card card-container"
        :body-style="{'padding': '16px'}"
      >
        <!-- Title -->
        <div slot="header" class="sub-title">
          <span class="title-hint">|</span>
          <span>排序{{ index + 1 }}</span>
          <img
            v-if="!showBase"
            class="button--delete"
            src="@/assets/setting/delete.svg"
            alt="Delete icon"
            @click="deleteItem(item.id)"
          >
        </div>

        <el-form
          ref="rulesForm"
          class="card__form"
          label-position="top"
          :model="displayFormData[index]"
          :rules="rules"
        >
          <el-form-item class="card__form__item" label="欄位名稱" prop="name">
            <el-input v-model="item.name" :disabled="showBase" />
          </el-form-item>
          <el-form-item class="card__form__item" label="Key" prop="key">
            <label slot="label">
              <span class="font-medium">Key</span>
              <span v-if="!showBase" class="enum__tip">(系統用代碼，僅能輸入英文與數字，最多15字)</span>
            </label>
            <el-input
              v-model="item.key"
              :disabled="showBase || !item.newField"
            />
          </el-form-item>
          <el-form-item v-if="!showBase" class="card__form__item" label="欄位備註">
            <el-input v-model="item.prompt" />
          </el-form-item>
          <el-form-item class="card__form__item" label="欄位種類" prop="type">
            <el-input v-if="showBase" v-model="item.type" :disabled="showBase" />
            <el-select
              v-else
              v-model="item.type"
              name="type"
            >
              <el-option
                v-for="subItem in typeOption"
                :key="subItem.value"
                :label="subItem.label"
                :value="subItem.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="showEnumField(item.type)"
            class="card__form__item"
            label="欄位選項"
            prop="enum"
          >
            <label slot="label">
              <span class="font-medium">欄位選項</span>
              <span class="enum__tip">(至少兩項，最多二十項)</span>
            </label>
            <el-select
              v-model="item.enum"
              style="height: 100%"
              multiple
              filterable
              allow-create
              :multiple-limit="20"
              @change="updateEnum(item.key, item.enum)"
            >
              <el-option
                v-for="subItem in item.enum"
                :key="subItem"
                :label="subItem"
                :value="subItem"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="card__form__item" label="欄位必填/選填" prop="required">
            <el-select
              v-model="item.required"
              :disabled="disableRequired(item.key)"
            >
              <el-option
                v-for="subItem in requiredOptions"
                :key="subItem.value"
                :label="subItem.label"
                :value="subItem.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="card__form__item" label="顯示於註冊過程" prop="display">
            <el-switch
              v-model="item.display"
              inactive-text="關閉"
              active-text="開啟"
              :disabled="disableDisplay(item.key)"
            />
          </el-form-item>
          <el-form-item class="card__form__item" label="開放後台編輯" prop="adminEdit">
            <el-switch
              v-model="item.adminEdit"
              inactive-text="關閉"
              active-text="開啟"
            />
          </el-form-item>
          <el-form-item class="card__form__item" label="會員卡資料設定" prop="adminEdit">
            <el-select
              :value="observeMemberOption(item)"
              @change="observeMemberOption = { index: index, value: $event }"
            >
              <el-option
                v-for="subItem in memberCenterOptions"
                :key="subItem.value"
                :label="subItem.label"
                :value="subItem.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeType === 'customized' && ['radio', 'checkbox'].includes(item.type)" class="card__form__item" label="會員標籤" prop="autoTag">
            <el-switch
              v-model="item.autoTag"
              inactive-text="關閉"
              active-text="開啟"
            />
          </el-form-item>
        </el-form>
      </el-card>
    </template>
    <el-row
      v-if="!showBase && formDataCustom.length < 20"
      type="flex"
      justify="center"
    >
      <el-button class="button--add" @click="addItem">新增 +</el-button>
    </el-row>

    <PageFixedFooter
      @cancel="$emit('close-user-info-setting')"
      @confirm="submitSetting"
    />
  </div>
</template>

<script>
export default {
  name: 'UserInfoSettingForm',
  props: {
    activeType: { type: String, required: true, default: 'base' },
    formData: { type: Array, required: true, default: () => [] },
  },
  data () {
    return {
      requiredOptions: [
        {
          label: '必填',
          value: true,
        },
        {
          label: '選填',
          value: false,
        },
      ],
      memberCenterOptions: [
        {
          label: '僅顯示於會員卡',
          value: 'only-show',
          show: true,
          edit: false,
        },
        {
          label: '會員卡檢視與編輯',
          value: 'show-edit',
          show: true,
          edit: true,
        },
        {
          label: '不顯示於會員卡',
          value: 'no-show',
          show: false,
          edit: false,
        },
      ],
      typeOption: [
        {
          label: '簡答',
          value: 'text',
        },
        {
          label: '詳答',
          value: 'richtext',
        },
        {
          label: '單選',
          value: 'radio',
        },
        {
          label: '多選',
          value: 'checkbox',
        },
        {
          label: '上傳圖片',
          value: 'restrictedImage',
        },
      ],
      formDataBase: this.convertToDisplayFormData('base'),
      formDataCustom: this.convertToDisplayFormData('customized'),
      rules: {
        name: [
          { required: true, message: '請輸入欄位名稱', trigger: 'blur' },
        ],
        key: [
          { required: true, message: '請輸入 Key', trigger: 'blur' },
          {
            validator: (_, value, callback) => {
              if (value === 'taxId') callback()
              const regexp = /^[a-z0-9]{1,15}$/
              if (!regexp.test(value)) callback(new Error('格式錯誤'))
              else callback()
            },
            trigger: 'blur',
          },
        ],
        type: [
          { required: true, message: '請輸入欄位種類', trigger: 'change' },
        ],
        enum: [
          { required: true, message: '請選擇欄位選項', trigger: 'change' },
          {
            validator: (_, value, callback) => {
              if (value.length < 2) callback(new Error('請至少選擇兩項'))
              else callback()
            },
            trigger: 'change',
          },
        ],
        required: [
          { required: true, message: '請選擇欄位必填/選填', trigger: 'change' },
        ],
      },
    }
  },

  computed: {
    showBase () {
      return this.activeType === 'base'
    },
    displayFormData () {
      return this.activeType === 'base' ? this.formDataBase : this.formDataCustom
    },
    showEnumField () {
      const showCustom = !this.showBase
      return (fieldType) => showCustom && ['radio', 'checkbox'].includes(fieldType)
    },
    disableRequired () {
      const keys = ['name']
      return (key) => keys.includes(key)
    },
    disableDisplay () {
      const keys = ['name']
      return (key) => keys.includes(key)
    },
    observeMemberOption: {
      get () {
        const defaultOption = this.memberCenterOptions[1]
        return (field) => {
          const { memberShow = true, memberEdit = true } = field
          const option = this.memberCenterOptions.find(opt => opt.show === memberShow && opt.edit === memberEdit) || defaultOption
          return option.value
        }
      },
      set ({ index, value }) {
        const { show, edit } = this.memberCenterOptions.find(opt => opt.value === value) || { show: false, edit: false }
        const currentFormData = this.showBase ? this.formDataBase : this.formDataCustom
        const currentField = currentFormData[index]
        currentFormData.splice(index, 1, { ...currentField, memberShow: show, memberEdit: edit })
      },
    },
  },

  methods: {
    convertToDisplayFormData (type) {
      const sortList = ['name', 'birthday', 'gender', 'email', 'address']
      const tempFormData = this.formData
        .filter(item => type === 'base' ? item.isBase : !item.isBase)
        .map(item => ({
          ...item,
          id: item.key,
        }))

      tempFormData.sort((a, b) => {
        return sortList.indexOf(a.key) - sortList.indexOf(b.key)
      })

      return tempFormData
    },

    convertToSubmitFormData () {
      let tempFormData = {}
      if (this.activeType === 'base') tempFormData = this.formDataBase
      if (this.activeType === 'customized') {
        tempFormData = this.formDataCustom
        tempFormData.forEach(item => {
          const type = item.type
          if (type === 'text') item.autoTag = false
          if (type === 'richtext') item.autoTag = false
        })
      }

      tempFormData = tempFormData.map(item => {
        const res = { ...item }
        delete res.id
        delete res.newField
        return res
      })

      const tempBase = this.formData.filter(item => item.isBase)
      const tempCustom = this.formData.filter(item => !item.isBase)

      return this.activeType === 'base' ? [...tempFormData, ...tempCustom] : [...tempBase, ...tempFormData]
    },
    submitSetting () {
      let hasError = false
      for (let count = 0; count < this.displayFormData.length; count += 1) {
        this.$refs.rulesForm[count].validate((valid) => {
          if (!valid) hasError = true
        })
      }
      if (hasError) return this.$message.error('資料未填寫完整')

      const tempFormData = this.convertToSubmitFormData()

      this.$emit('submit-setting', tempFormData)
    },

    addItem () {
      const addItem = {
        id: Date.now(),
        name: '', // 欄位名稱
        key: '', // 系統代碼
        type: '', // 欄位種類
        prompt: undefined, // 欄位備註
        isBase: false, // 是否為固定欄位
        display: true, // 顯示於註冊過程
        required: '', // 必填/選填
        adminEdit: true, // 後台是否能更改
        memberShow: true, // 會員卡檢視
        memberEdit: true, // 會員卡編輯
        newField: true, // 區隔新增自定義欄位
        autoTag: false,
      }
      this.formDataCustom.push(addItem)
    },

    deleteItem (deleteTargetId) {
      const deleteIndex = this.formDataCustom.findIndex(item => item.id === deleteTargetId)
      this.formDataCustom.splice(deleteIndex, 1)
    },

    updateEnum (targetKey, updatedEnum) {
      this.formDataCustom.forEach(item => {
        if (item.key === targetKey) item.enum = [...updatedEnum]
      })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-select .el-input {
  @apply h-full;
}
::v-deep .el-tag--info {
  @apply h-full break-all;
  white-space: break-spaces;
}
::v-deep .el-select__tags {
  @apply flex flex-wrap;
}

::v-deep .box-card {
  @apply relative;
  @apply mb-5;
  .el-card__header {
    @apply py-[9px] border-b-0;
  }
}

.card__form {
  @apply flex flex-wrap;
}

.card__form__item {
  flex: 0 0 50%;
  @apply mb-6;
}

.sub-title {
  @apply text-[24px] font-medium;
}

.title-hint {
  color: var(--primary-100);
  @apply mr-[5px] items-center;
}

.enum__tip {
  @apply text-sub text-gray-80 font-normal;
}
.button--delete {
  @apply absolute top-[23px] right-[25px];
  @apply w-[14px] h-[18px] cursor-pointer;
}

.button--add {
  @apply mt-3 w-1/2 h-[44px];
}
</style>
