<template>
  <div class="tip-info">
    <el-tooltip :placement="placement">
      <div slot="content" :style="computedStyle">
        <slot />
      </div>
      <MaterialIcon :size="size">help_outline</MaterialIcon>
    </el-tooltip>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import MaterialIcon from '@/components/MaterialIcon.vue'
export default defineComponent({
  name: 'TipInfo',
  components: { MaterialIcon },
  props: {
    width: { type: [String, Number], default: 'auto' },
    size: { type: Number, default: 24 },
    placement: { type: String, default: 'right' },
  },
  setup (props) {
    const computedStyle = computed(() => {
      return {
        width: props.width ? `${props.width}px` : 'auto',
      }
    })
    return { computedStyle }
  },
})
</script>

<style lang="postcss" scoped>
.tip-info {
  @apply w-[fit-content] h-[fit-content];
  @apply flex items-center justify-center;
  @apply cursor-help;
}
</style>
