import { admin2 } from './instance'

export const GetMemberPolicyConfig = ({ orgId }) => {
  return admin2({
    url: `/${orgId}/rightPolicyConfig`,
    method: 'GET',
  })
}
export const UpdateMemberPolicyConfig = ({
  enable,
  name,
  content,
  informClient,
  OrgId,
}) => {
  return admin2({
    url: `/${OrgId}/rightPolicyConfig`,
    method: 'PUT',
    data: {
      enable,
      name,
      content,
      informClient,
      OrgId,
    },
  })
}
export const ResetMemberPolicyStatus = ({ orgId }) => {
  return admin2({
    url: `/${orgId}/rightPolicyConfig/revertMemberRightPolicyStatus`,
    method: 'POST',
    data: {},
  })
}
